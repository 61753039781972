import React from "react"
import { Link } from "gatsby"

// Components
import Layout from "../../components/layout"
import Band from "../../components/band"
import SEO from "../../components/seo"

// CSS
import styles from "./products.module.css"
import shared from "../../shared/shared.module.css"
import cardStyles from "../../shared/card.module.css"
import grid from "../../shared/bootstrap-grid.module.css"

// SVG
import Flowdatatable from "../../svg/FlowDataTable/FlowDataTableSortSelect.svg"
import PhoneValidation from "../../svg/PhoneValidation/PV-Table-GrayBG.svg"
import Tigerfacehelmet from "../../svg/tigerface-helmet.svg"

const products = [
  {
    title: 'Flow Data Table',
    Image: Flowdatatable,
    description: 'A Lightning Component which can be placed onto a Screen within the Salesforce Flow Builder in order to display a list of records in table format.',
    link: '/products/flow-data-table/summary'
  },
  {
    title: 'Phone Validation',
    Image: PhoneValidation,
    description: 'Highly configurable Related List and List View Lightning Components for displaying and validating phone numbers.',
    link: '/products/phone-validation/summary'
  }
];

const ProductCard = ({ title, Image, description, link }) => (
  <Link to={link} className={`${styles.productCard} ${cardStyles.cardLink} ${cardStyles.cardHover}`}>
    <h3 className={styles.productCardTitle}>{title}</h3>
    <Image className={styles.tableImage} />
    <p className={styles.productCardText}>{description}</p>
    <span className={shared.tigerfaceLink}>
      Learn More
    </span>
  </Link>
);

const EmptyProductCard = () => (
  <div className={`${styles.productCardEmpty} ${grid.mt4}`}>
    <Tigerfacehelmet style={{ maxWidth: '75px' }} />
    <h3 className={`${styles.productCardTitle} ${grid.mt3}`}>More to come...</h3>
  </div>
);

const ProductCards = () => products
  .map((product, index) => (
    <div className={`${grid.colLg6} ${grid.mt4} ${index > 1 ? grid.mt4 : grid.mtLg0}`}>
      <ProductCard
        title={product.title}
        Image={product.Image}
        description={product.description}
        link={product.link}
      />
    </div>
  )).concat([
    <div className={`${grid.colLg6}`}>
      <EmptyProductCard />
    </div>
  ]);

const ProductsPage = () => (
  <Layout>
    <SEO title="Products" keywords={[`tigerface`, `systems`, `salesforce`, `products`, `flow data table`]} />

    <Band>
      <h1>Products</h1>
      <div className={grid.row}>
        <ProductCards />
      </div>
    </Band>
  </Layout>
);

export default ProductsPage;